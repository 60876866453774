<template>
  <div>
    <div class="wrap main_content">
      <div class="fl my">
        <div class="myTxt bgw">
          <img
            v-if="isDefaultImg || userlist.avatar == null || userlist.avatar == ''"
            class="myHeaderImg fl"
            src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
          />
          <img v-else :src="userlist.avatar" @error="isDefaultImg = true" class="myHeaderImg fl" />
          <div class="fr txt">
            <p>
              用户名称:
              <span>{{ userlist.user_nickname }}</span>
              出生日期:
              <span>{{ userlist.birthday }}</span>
              性别:
              <span>{{ userlist.sex === 0 ? '保密' : (userlist.sex === 1 ? '男' : '女') }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="fr nav">
        <h1>更多功能</h1>
        <p>
          <img src="@/assets/image/right_Customerservice@2x.png" alt />
          <a
            :href="`tencent://message/?uin=${this.sysconfs.kefu_qq}&Menu=yes&Service=300&sigT=42a1e5347953b64c5ff3980f8a6e644d4b31456cb0b6ac6b27663a3c4dd0f4aa14a543b1716f9d45`"
          >联系客服</a>
        </p>
        <p @click="commitHandle(3)">
          <img src="@/assets/image/right_Furtherconsultation@2x.png" alt />复诊
        </p>
        <p @click="commitHandle(4)">
          <img src="@/assets/image/right_On-line@2x.png" alt />专家在线
        </p>
        <p @click="commitHandle(5)">
          <img src="@/assets/image/right_Functionalfeedback@2x.png" alt />功能反馈
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getSysconfs } from "@/request/public";

export default {
  props: ["userlist"],
  data() {
    return {
      isDefaultImg: false,
      sysconfs: {}
    };
  },
  created() {
    getSysconfs({ token: this.$token }).then(res => {
      if(res.code == 1) {
        this.sysconfs = res.data;
      }
    });
  },
  methods: {
    commitHandle(num) {
      this.$emit("changeState", num);
    }
  }
};
</script>
<style lang="less" scoped>
.nav {
  width: 201px;
  height: 100%;
  background: white;
  padding-top: 26px;
  padding-left: 38px;
  font-size: 16px;
  h1 {
    font-size: 16px;
    margin-bottom: 30px;
  }
  p {
    cursor: Pointer;
    height: 50px;
    line-height: 50px;
  }
  img {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }
}
.discount {
  width: 440px;
  height: 4146px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 32px;
  .all {
    position: absolute;
    bottom: 27px;
    right: 23px;
    color: #4eeec8;
    font-size: 18px;
    cursor: Pointer;
  }
  h1 {
    font-size: 24px;
    font-weight: 400;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 168px;
      height: 116px;
      background: #4eeec8;
      margin-top: 32px;
      text-align: center;
      cursor: Pointer;
    }
  }
  .msg1 {
    font-size: 26px;
    color: white;
    margin-top: 12px;
  }
  .msg2 {
    font-size: 16px;
    color: white;
    margin-top: 2px;
  }
  .msg3 {
    width: 112px;
    height: 28px;
    line-height: 28px;
    background: rgba(255, 255, 255, 1);
    border-radius: 14px;
    color: #4eeec8;
    margin: auto;
    margin-top: 8px;
  }
}
.integral {
  // margin-top: 26px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 32px;
  width: 508px;
  height: 153px;
  position: relative;
  h1 {
    font-size: 24px;
    font-weight: 400;
  }
  p {
    margin-top: 34px;
    width: 82%;
    color: #808080;
    font-size: 18px;
  }
  span:nth-of-type(1) {
    position: absolute;
    bottom: 32px;
    right: 32px;
    display: inline-block;
    width: 67px;
    height: 28px;
    background: rgba(78, 238, 200, 1);
    border-radius: 13px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    cursor: Pointer;
  }
  span:nth-of-type(2) {
    cursor: Pointer;
    position: absolute;
    top: 35px;
    right: 35px;
    color: #4eeec8;
  }
}
.goodsOrder {
  margin-bottom: 26px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 32px;
  width: 508px;
  height: 269px;
  h1 {
    font-size: 24px;
    font-weight: 400;
  }
  button {
    width: 100%;
    height: 60px;
    font-size: 18px;
    background: #656565;
    margin-top: 36px;
    cursor: Pointer;
  }
  .allOrder {
    display: flex;
    margin-top: 24px;
    text-align: center;
    div {
      cursor: Pointer;
      width: 50%;
      height: 72px;
      border-left: 1px dashed #e6e6e6;
      color: #808080;
      line-height: 28px;
      padding-top: 8px;
    }
    div:nth-of-type(1) {
      border-left: none;
    }
  }
}
.order {
  margin-top: 26px;
}
.txt {
  width: 723px;
  text-align: left;
  font-size: 18px;
  p {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  span {
    color: #808080;
    margin-left: 10px;
    margin-right: 59px;
  }
}
.myTxt {
  height: 220px;
  padding-left: 54px;
  padding-top: 35px;
  > img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  p {
  }
}

.my {
  width: 973px;
  height: 100%;
  position: relative;
}

.main_content {
  overflow: hidden;
  height: 694px;
}
</style>